<template>
  <section class="section m-0 p-1 has-background-primary">
    <router-view />
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style>
</style>
